import React, { useState, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ReactComponent as Branding } from '../assets/Branding/correctBranding.svg';
import { useScroll } from '../utils/ScrollContext';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../utils/LanguageContext';

const Header: React.FC = () => {
    const { t } = useTranslation();
    const languageContext = useContext(LanguageContext);

    if (!languageContext) {
        throw new Error('LanguageContext is undefined. Make sure you are using LanguageProvider correctly.');
    }

    const { language, changeLanguage } = languageContext;
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isMobileDropdownOpen, setMobileDropdownOpen] = useState(false);
    const [isLangDropdownOpen, setLangDropdownOpen] = useState(false);
    const { scrollToSection, scrollToTop } = useScroll();
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavClick = (path: string, section?: string) => {
        setMobileMenuOpen(false);
        if (location.pathname !== '/') {
            navigate('/', { state: { section } });
        } else {
            if (section) {
                scrollToSection(section);
            } else {
                scrollToTop();
            }
        }
    };

    const dropdownVariants = {
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0 }
    };

    const lineVariants = {
        hidden: { scaleY: 0 },
        visible: { scaleY: 1, transition: { duration: 0.4 } }
    };

    const circleVariants = {
        hidden: { scale: 0 },
        visible: { scale: 1, transition: { delay: 0.4, duration: 0.2 } }
    };

    const menuVariants = {
        hidden: { opacity: 0, x: '100%' },
        visible: { opacity: 1, x: '0%' }
    };

    return (
        <>
            <header className="bg-nisipeanu-black-dark text-nisipeanu-white fixed w-full z-30 top-0 left-0">
                <nav className="max-w-screen-xl mx-auto flex justify-between items-center p-4 md:p-3 h-16 md:h-auto">
                    <div className="flex items-center space-x-4">
                        <Branding className="lg:h-10 h-8 w-auto" />
                    </div>
                    <div className="md:hidden">
                        <button onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
                                className="text-gray-400 hover:text-gray-300 focus:text-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300">
                            <span className="sr-only">{t('menu.open')}</span>
                            <svg className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                            </svg>
                        </button>
                    </div>
                    <div className="hidden md:flex md:space-x-8 items-center">
                        <Link to="/" className="py-2 px-3 text-white hover:bg-nisipeanu-grey-light rounded"
                              onClick={() => handleNavClick('/')}>{t('nav.home')}</Link>
                        <Link to="#" className="py-2 px-3 text-white hover:bg-nisipeanu-grey-light rounded"
                              onClick={() => handleNavClick('/', 'aboutMe')}>{t('nav.aboutMe')}</Link>
                        <button
                            onClick={() => handleNavClick('/', 'careerHighlights')}
                            className="py-2 px-3 text-white hover:bg-nisipeanu-grey-light rounded"
                        >
                            {t('nav.careerHighlights')}
                        </button>

                        {/* Partners Dropdown */}
                        <div className="relative">
                            <button onClick={() => setDropdownOpen(!isDropdownOpen)}
                                    className="py-2 px-3 text-white hover:bg-nisipeanu-grey-light rounded flex items-center">
                                {t('nav.partners')}
                                <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 20 20">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l5-5 5 5"/>
                                </svg>
                            </button>
                            {isDropdownOpen && (
                                <motion.div initial="hidden" animate="visible" exit="hidden" variants={dropdownVariants}
                                            transition={{ duration: 0.2 }}
                                            className="absolute bg-nisipeanu-black w-56 mt-1 rounded">
                                    {/* <Link to="https://www.linkedin.com/in/mariusvalentindragoi/?originalSubdomain=ro" className="block py-2 px-4 text-white hover:bg-nisipeanu-black">{t('partners.marius')}</Link> */}
                                    <Link to="https://www.facebook.com/AllenArtDesign" className="block py-2 px-4 text-white hover:bg-nisipeanu-black">{t('partners.allen')}</Link>

                                    {/* Line and Circle Animation */}
                                    <motion.div
                                        className="absolute right-4 top-0 mb-5 h-full w-1 bg-nisipeanu-blue shadow-glow"
                                        variants={lineVariants} style={{transformOrigin: "top"}}></motion.div>
                                    <motion.div
                                        className="absolute right-4 top-full -mt-1.5 -mr-3 w-5 h-5 rounded-full border-4 border-nisipeanu-blue shadow-glow"
                                        variants={circleVariants}></motion.div>
                                </motion.div>
                            )}
                        </div>

                        <Link to="/portfolio"
                              className="py-2 px-3 text-white hover:bg-nisipeanu-grey-light rounded">{t('nav.portfolio')}</Link>

                        {/* Language Dropdown with Line and Circle Animation */}
                        <div className="relative">
                            <button onClick={() => setLangDropdownOpen(!isLangDropdownOpen)}
                                    className="py-2 px-3 text-white hover:bg-nisipeanu-grey-light rounded flex items-center">
                                {language === 'en' ? 'English' : 'Română'}
                                <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 20 20">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l5-5 5 5"/>
                                </svg>
                            </button>
                            {isLangDropdownOpen && (
                                <motion.div initial="hidden" animate="visible" exit="hidden" variants={dropdownVariants}
                                            transition={{ duration: 0.2 }}
                                            className="absolute bg-nisipeanu-black w-40 mt-1 rounded">
                                    <button className="block w-full text-left py-2 px-4 text-white hover:bg-nisipeanu-grey-light"
                                            onClick={() => { changeLanguage('en'); setLangDropdownOpen(false); }}>
                                        English
                                    </button>
                                    <button className="block w-full text-left py-2 px-4 text-white hover:bg-nisipeanu-grey-light"
                                            onClick={() => { changeLanguage('ro'); setLangDropdownOpen(false); }}>
                                        Română
                                    </button>

                                    {/* Line and Circle Animation */}
                                    <motion.div
                                        className="absolute right-4 top-0 mb-5 h-full w-1 bg-nisipeanu-blue shadow-glow"
                                        variants={lineVariants} style={{transformOrigin: "top"}}></motion.div>
                                    <motion.div
                                        className="absolute right-4 top-full -mt-1.5 -mr-3 w-5 h-5 rounded-full border-4 border-nisipeanu-blue shadow-glow"
                                        variants={circleVariants}></motion.div>
                                </motion.div>
                            )}
                        </div>
                    </div>
                </nav>

                {/* Mobile menu */}
                <motion.div
                    initial="hidden"
                    animate={isMobileMenuOpen ? 'visible' : 'hidden'}
                    variants={menuVariants}
                    transition={{ duration: 0.5 }}
                    className="fixed inset-0 bg-nisipeanu-black-dark z-40 flex flex-col items-center justify-center md:hidden"
                >
                    <button onClick={() => setMobileMenuOpen(false)}
                            className="absolute top-5 right-5 text-gray-400 hover:text-gray-300 focus:text-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300">
                        <span className="sr-only">{t('menu.close')}</span>
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <Link to="/" className="py-2 px-3 text-white text-2xl hover:bg-nisipeanu-grey-light rounded"
                          onClick={() => { setMobileMenuOpen(false); navigate('/'); }}>{t('nav.home')}</Link>
                    <Link to="/" className="py-2 px-3 text-white text-2xl hover:bg-nisipeanu-grey-light rounded"
                          onClick={() => { setMobileMenuOpen(false); handleNavClick('/', 'aboutMe'); }}>{t('nav.aboutMe')}</Link>
                    <button
                        onClick={() => { setMobileMenuOpen(false); handleNavClick('/', 'careerHighlights'); }}
                        className="py-2 px-3 text-white text-2xl hover:bg-nisipeanu-grey-light rounded"
                    >
                        {t('nav.careerHighlights')}
                    </button>
                    <div className="relative w-full text-center">
                        <button onClick={() => setMobileDropdownOpen(!isMobileDropdownOpen)}
                                className="py-2 px-3 text-white text-2xl hover:bg-nisipeanu-grey-light rounded flex items-center justify-center w-full">
                            {t('nav.partners')}
                            <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 20 20">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M5 10l5-5 5 5"/>
                            </svg>
                        </button>
                        {isMobileDropdownOpen && (
                            <div className="mt-2 bg-nisipeanu-black text-white text-lg rounded-lg w-full">
                                {/* <Link to="https://www.linkedin.com/in/mariusvalentindragoi/?originalSubdomain=ro" className="block py-2 px-4 hover:bg-nisipeanu-grey-light" onClick={() => setMobileMenuOpen(false)}>{t('partners.marius')}</Link> */}
                                <Link to="https://www.facebook.com/AllenArtDesign" className="block py-2 px-4 hover:bg-nisipeanu-grey-light" onClick={() => setMobileMenuOpen(false)}>{t('partners.allen')}</Link>
                            </div>
                        )}
                    </div>
                    <Link to="/portfolio"
                          className="py-2 px-3 text-white text-2xl hover:bg-nisipeanu-grey-light rounded"
                          onClick={() => { setMobileMenuOpen(false); navigate('/portfolio'); }}>{t('nav.portfolio')}</Link>

                    {/* Mobile Language Switcher */}
                    <div className="relative w-full text-center">
                        <button onClick={() => setLangDropdownOpen(!isLangDropdownOpen)}
                                className="py-2 px-3 text-white text-2xl hover:bg-nisipeanu-grey-light rounded flex items-center justify-center w-full">
                            {language === 'en' ? 'English' : 'Română'}
                            <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 20 20">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l5-5 5 5"/>
                            </svg>
                        </button>
                        {isLangDropdownOpen && (
                            <motion.div initial="hidden" animate="visible" exit="hidden" variants={dropdownVariants}
                                        transition={{ duration: 0.2 }}
                                        className="mt-2 bg-nisipeanu-black text-white text-lg rounded-lg w-full">
                                <button className="block w-full text-left py-2 px-4 text-white hover:bg-nisipeanu-grey-light"
                                        onClick={() => { changeLanguage('en'); setLangDropdownOpen(false); }}>
                                    English
                                </button>
                                <button className="block w-full text-left py-2 px-4 text-white hover:bg-nisipeanu-grey-light"
                                        onClick={() => { changeLanguage('ro'); setLangDropdownOpen(false); }}>
                                    Română
                                </button>

                                {/* Line and Circle Animation for mobile dropdown */}
                                <motion.div
                                    className="absolute right-4 top-0 mb-5 h-full w-1 bg-nisipeanu-blue shadow-glow"
                                    variants={lineVariants} style={{transformOrigin: "top"}}></motion.div>
                                <motion.div
                                    className="absolute right-4 top-full -mt-1.5 -mr-3 w-5 h-5 rounded-full border-4 border-nisipeanu-blue shadow-glow"
                                    variants={circleVariants}></motion.div>
                            </motion.div>
                        )}
                    </div>
                </motion.div>
            </header>
        </>
    );
};

export default Header;
